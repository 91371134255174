<!--
 * @Author: 张博洋
 * @Date: 2022-02-24 14:49:53
 * @LastEditTime: 2022-02-24 16:16:04
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/follow/views/status.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>

    <div class="status"
         v-if="status === 1">
      <van-icon name="underway-o"
                size="24"
                color="#FF8200" />
      <span style="color: #FF8200">{{transformState(status)}}</span>
    </div>

    <div class="status"
         v-if="status === 2">
      <van-icon name="passed"
                size="24"
                color="#1AB370" />

      <span style="color:#1AB370">{{transformState(status)}}</span>
    </div>

    <div class="status"
         v-if="status === 3">
      <van-icon name="passed"
                size="24"
                color="#1AB370" />
      <span style="color:#1AB370">{{transformState(status)}}</span>
    </div>

    <div class="status"
         v-if="status === 4">
      <span class="iconfont icon-quxiao"></span>
      <span style="color: #999">{{transformState(status)}}</span>
    </div>
  </div>

</template>

<script>
import '@/assets/iconfont.css'
export default {
  props: ['status'],
  methods: {
    transformState(status) {
      switch (status) {
        case 1: {
          return '待随访'
        }
        case 2: {
          return '随访中'
        }
        case 3: {
          return '已随访'
        }
        case 4: {
          return '已取消'
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.status {
  background: #ffffff;
  border-radius: 8px;
  margin: 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px 0;
  span {
    font-size: 18px;
    font-weight: 400;
    margin-left: 5px;
  }
  span.iconfont {
    color: #999999;
    font-size: 24px;
    font-weight: 600;
  }
}
</style>