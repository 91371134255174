<!--
 * @Author: 张博洋
 * @Date: 2022-02-21 14:50:28
 * @LastEditTime: 2022-03-07 10:55:25
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/follow/detail.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="wrap">

    <status :status="detail.status"></status>

    <div class="plane mt8">
      <van-cell-group inset>
        <h1>{{detail.planName}}</h1>
        <van-cell title="随访时间"
                  :value="$.replyTimeToDay(detail.expectTime)" />
        <van-cell title="关键词"
                  :value="detail.drugName" />
        <van-cell title="随访描述"
                  :value="detail.imDesc" />
      </van-cell-group>
    </div>

    <div class="plane mt8">
      <van-cell-group inset>
        <h1>就诊人信息</h1>
        <van-cell title="就诊人姓名"
                  :value="`${detail.patientName} ${detail.patientSex === 1 ? '男' : '女'} ${detail.patientAge}岁` " />
        <van-cell title="患者手机号"
                  :value="detail.patientPhone" />

      </van-cell-group>
    </div>

    <follow-form :formJson="formJson"
                 :detail="detail"></follow-form>
  </div>
</template>

<script>
import FollowForm from './views/form.vue'
import status from './views/status.vue'
export default {
  data() {
    return {
      formJson: {},
      detail: {
        patientName: '-',
        patientSex: '',
        patientAge: '-',
        patientPhone: '-',
        drugName: '-',
        imDesc: '-',
      },
    }
  },
  components: {
    FollowForm,
    status,
  },
  created() {
    this.getDetail()
  },

  methods: {
    getDetail() {
      this.$axios({
        type: 'get',
        url: 'visitTask/detail',
        data: {
          id: this.$route.query.taskId,
        },
        elseData: {
          ajaxLoading: true,
          loading: true,
          errorTip: false,
        },
      }).then((res) => {
        console.log(res)
        this.detail = res.d
        console.log(this.formJson)
        // if (!res.d.formJson) this.formJson = {}
        res.d.formJson
          ? (this.formJson = JSON.parse(res.d.formJson))
          : (this.formJson = {})
      })
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.van-cell-group--inset {
  margin: 0 8px;
}
.wrap {
  height: 100%;
  overflow: auto;

  /deep/.van-cell-group {
    h1 {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      padding: 12px 16px;
    }
    .van-cell__title {
      color: #666;
    }
    .van-cell__value {
      color: #333;
      text-align: left;
    }
  }

  .btn-group {
    background: #fff;
    padding: 10px 25px 30px 25px;
    .van-button {
      width: 100%;
      margin: 0;
    }
  }
}
</style>

